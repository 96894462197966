<template>
  <b-container fluid v-if="$root.$data.me.PortalAdmin">
    <b-row class="mb-4">
      <b-col>
        <h1>Page Snippets</h1>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-table-simple>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th style="width: 10%;">Name</b-th>
              <b-th style="width: 40%;">Default</b-th>
              <b-th style="width: 40%;">Custom</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in items" :key="item.name">
              <b-td>{{ item.Name }}</b-td>
              <b-td v-html="item.Default"></b-td>
              <b-td>
                <div v-html="item.Custom"></div>
                <b-button variant="primary" :to="{ name: 'pagesnippet-detail', params: { id: item.Name } }">
                  <template v-if="item.Custom == null">Create custom snippet</template>
                  <template v-if="item.Custom != null">Edit custom snippet</template>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'PageSnippets',
    components: {
    },
    data () {
      return {
        filter: { q: '' },
        items: null,
        cancelToken: null,
        loading: true,
      }
    },
    mounted () {
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.cancelToken = axios.CancelToken.source()

      this.loading = true
      this.$http
          .get('page-snippets-combined', { cancelToken: this.cancelToken.token })
          .then(response => {
              this.totalRows = Number(response.headers['x-pagination-count'])
              this.loading = false
              
              this.items = response.data
          }).catch(function () {
              this.loading = false
          })
    }
  }
</script>

<style scoped>
</style>