var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.me.PortalAdmin
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [_c("b-col", [_c("h1", [_vm._v("Page Snippets")])])],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-table-simple",
                    [
                      _c(
                        "b-thead",
                        { attrs: { "head-variant": "dark" } },
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", { staticStyle: { width: "10%" } }, [
                                _vm._v("Name")
                              ]),
                              _c("b-th", { staticStyle: { width: "40%" } }, [
                                _vm._v("Default")
                              ]),
                              _c("b-th", { staticStyle: { width: "40%" } }, [
                                _vm._v("Custom")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        _vm._l(_vm.items, function(item) {
                          return _c(
                            "b-tr",
                            { key: item.name },
                            [
                              _c("b-td", [_vm._v(_vm._s(item.Name))]),
                              _c("b-td", {
                                domProps: { innerHTML: _vm._s(item.Default) }
                              }),
                              _c(
                                "b-td",
                                [
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(item.Custom) }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        to: {
                                          name: "pagesnippet-detail",
                                          params: { id: item.Name }
                                        }
                                      }
                                    },
                                    [
                                      item.Custom == null
                                        ? [_vm._v("Create custom snippet")]
                                        : _vm._e(),
                                      item.Custom != null
                                        ? [_vm._v("Edit custom snippet")]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }